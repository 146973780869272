import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Label } from ":src/components/form";
// import { InputText } from "primereact/";
import { useForm } from "react-hook-form";
import { LoginSchema } from "./form/login-schema";
import { global } from ":global";
import { useEffect } from "react";
import { navigate } from "wouter/use-browser-location";

const signin = {
  email: "abdo@gmail.com",
  password: "123456789sK",
};
const is_dev = import.meta.env.DEV;
export default function Signin() {
  const form = useForm({
    // @ts-ignore
    defaultValues: is_dev && signin,
    resolver: (values) =>
      global.schema_validator.reactHookFormResolver(LoginSchema, values),
  });
  const $user = global.store.hooks.global.useUser();

  useEffect(() => {
    if ($user.initedAndExist) {
      navigate("/admin");
    }
  }, [$user]);

  async function onSubmit(data: any) {
    global.fb.auth
      .signinWithEmailAndPassword(data, { persist: "browser_session" })
      .then((user) => {
        console.log("auth is :: ", user);
      });
    // global.fb.localApp
    //   .auth()
    //   .signInWithEmailAndPassword(data.email, data.password)
    //   .then((info) => {
    //     console.log(info);
    //     return Promise.resolve(info);
    //   });
    // const auth = global.fb.localAuth.getAuth();
    // global.fb.localAuth
    //   .setPersistence(auth, global.fb.localAuth.browserLocalPersistence)
    //   .then(async () => {
    //     return global.fb.localAuth
    //       .signInWithEmailAndPassword(auth, data.email, data.password)
    //       .then((info) => {
    //         console.log(info);
    //         return Promise.resolve(info);
    //       });
    //   });
  }
  return (
    <div className="flex flex-col w-full h-full px-3 py-1 ">
      <h2>Slido Knockoff</h2>
      <div className="flex flex-col space-y-2">
        <Label
          for="email"
          title="Email"
          error={form.formState?.errors?.email}
        />
        <InputText {...form.register("email")} type="email" />
        <Label
          for="assword"
          title="Password"
          error={form.formState?.errors?.password}
        />
        <InputText {...form.register("password")} type="password" />
        <Button
          label="Submit"
          className="w-fit self-end !mt-4 !mb-2"
          onClick={form.handleSubmit(onSubmit, (error) => {
            console.log("on invalid :: ", error);
          })}
        />
      </div>
    </div>
  );
}
